import React, { useEffect } from "react";

export const Paginator = ({
    data,
    loading,
    setPage,
    setIntermidiatePageNumber,
    intermidiatePageNumber,
    setPageSize,
    pageSize,
}) => {
    // useEffect(()=>{},[])
    return (
        <div className="mt-2 flex justify-between items-center">
            <div className="flex space-x-1 items-center">
                {data.current_page > 1 && (
                    <button
                        disabled={loading}
                        onClick={() => {
                            setPage((prev) => prev - 1);
                        }}
                        className="disabled:cursor-not-allowed text-black underline px-0 py-0 rounded-lg"
                    >
                        Back
                    </button>
                )}

                <div className="p-1 rounded border-black border-2 flex space-x-1 items-center">
                    <input
                        value={loading ? "loading..." : intermidiatePageNumber}
                        type="number"
                        className="disabled:cursor-not-allowed mx-2 w-10"
                        disabled={loading}
                        onChange={(e) => {
                            setIntermidiatePageNumber(Number(e.target.value));
                        }}
                    />
                    <button
                        onClick={() => {
                            if (
                                intermidiatePageNumber > 0 &&
                                intermidiatePageNumber <= data.total_pages
                            ) {
                                setPage(intermidiatePageNumber);
                            } else {
                                alert("Please enter correct number range");
                            }
                        }}
                        className="bg-black px-1 py-1 text-xs rounded text-white"
                    >
                        Apply
                    </button>
                </div>
                {data.current_page < data.total_pages && (
                    <button
                        disabled={loading}
                        onClick={() => {
                            setPage((prev) => prev + 1);
                        }}
                        className="ml-3 disabled:cursor-not-allowed text-black underline px-0 py-0 rounded-lg"
                    >
                        Next
                    </button>
                )}

                <select
                    value={pageSize}
                    onChange={(e) => {
                        setPageSize(e.target.value);
                    }}
                >
                    <option value={""}>choose</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={100}>150</option>
                    <option value={data.count}>All</option>
                </select>
            </div>
            <div className="flex space-x-1">
                <div>Total Pages: {data.total_pages}</div>

                <div>Count: {data.count}</div>
            </div>
        </div>
    );
};
