import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useAxiosInstance from "../../hooks/useAxios";
import { Paginator } from "../../components/Paginator";
import { useDebounce } from "use-debounce";
import Input from "../../components/input";
import { toast } from "react-toastify";

const NewsletterAccessDetailPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const newsletterTypeId = searchParams.get("type_id");
  const newsletterTypeName = searchParams.get("type_name");

  const [allTypes, setAllTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const axiosInstance = useAxiosInstance();

  const [page, setPage] = useState(1);
  const [intermidiatePageNumber, setIntermidiatePageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  // const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const [orgActiveStatus, setOrgActiveStatus] = useState("");
  const [hidden, setHidden] = useState("all");
  const [query, setQuery] = useState("");
  const [debounceSearch] = useDebounce(query, 1000);
  const [trigggerRefreash,setTriggerRefresh] = useState(1);

  const [actions,setActions] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    const controller = new AbortController();

    axiosInstance
      .post(
        `/newsletter/v2/mass_view_all_newsletters/`,
        {
          newsletter_type__pk: Number(newsletterTypeId),
          org_active_status: orgActiveStatus,
          org_query: debounceSearch,
          page: page,
          page_size: pageSize,
          hidden: hidden==='all'? 'all' : hidden==="true"?true:false
        },
        {
          signal: controller.signal,
        }
      )
      .then((res) => {
        setAllTypes(res.data.data);
        setIntermidiatePageNumber(res.data.current_page)
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));

    return () => {
      controller.abort();
    };
  }, [newsletterTypeId, orgActiveStatus, page, pageSize,hidden,debounceSearch,trigggerRefreash]);

  useEffect(() => {
    setPage(1);
  }, [orgActiveStatus, debounceSearch, pageSize,hidden]);

  function handleModify(org_id, type_id,action,org_name) {
    const confirm = window.confirm(`Are you sure on performing mark ${action} on org  ${org_name}?`)
    if(!confirm){
      return 
    }
    const id = `${org_id}|${action}`
    setActions(prev=>[...prev, id])
    const payload = {}
    if(action === 'admin'){
      payload['is_user_only'] = false
      payload['is_admin_only'] = true
      payload['is_hidden'] = false      
    }
    if(action === 'user'){
      payload['is_user_only'] = true
      payload['is_admin_only'] = false
      payload['is_hidden'] = false      
    }
    if(action === 'hide'){
      payload['is_user_only'] = false
      payload['is_admin_only'] = false
      payload['is_hidden'] = true      
    }
    if(action === 'visible'){
      payload['is_user_only'] = false
      payload['is_admin_only'] = false
      payload['is_hidden'] = false      
    }
    axiosInstance
      .post(
        `/newsletter/v2/give_mass_access_for_orgs/`,
        {
          org_name__pks: [Number(org_id)],
          newsletter_type__pk: Number(newsletterTypeId),
          ...payload
        }).then(()=>{
          setActions(prev=>prev.filter(it=>it!==id))
          setTriggerRefresh(prev=>prev+1)
          toast.success("successfully marked")
        }).catch(err=>{

        }) 
  }
  return (
    <div className="min-h-screen w-full">
      <div className="p-6 w-full">
        <div className="mb-4 w-full">
          <div className="text-2xl font-semibold p-3 border bg-white rounded-xl">
            {newsletterTypeName}
          </div>
          <div className="grid grid-cols-3 gap-x-2 mt-4"><input
                className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 "
                value={query}
                placeholder="Search name"
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
              />
              <select
                value={hidden}
                onChange={(e) => {
                  setHidden(e.target.value);
                }}
                className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 "
                placeholder="Enter your password"
              >
                <option value={"all"}>all</option>
                <option value={"true"}>Hidden</option>
                <option value={"false"}>Visible</option>
              </select>
              <select
                value={hidden}
                onChange={(e) => {
                  setOrgActiveStatus(e.target.value);
                }}
                className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 "
                placeholder="Enter your password"
              >
                <option value={"all"}>all orgs</option>
                <option value={"active"}>Active orgs</option>
                <option value={"inactive"}>Inactive orgs</option>
              </select></div>
          {isLoading ? (
            <>Loading....</>
          ) : (
            <div className="grid grid-cols-1 gap-1 mt-5 w-full">
             
              {allTypes.map((org_info) => {
                return (
                  <div
                    className="p-3 border items-center rounded-lg bg-white grid grid-cols-3"
                    key={org_info.pk}
                  >
                    <div className="flex items-center gap-x-2">
                      <img
                        className="w-[40px] rounded-xl"
                        src={
                          org_info.org_logo
                            ? `https://wklogo.blob.core.windows.net/logos-templates/${org_info.org_logo}-light.png`
                            : `https://wklogo.blob.core.windows.net/logos/_${org_info.org_name?.[0]?.toLowerCase()}.png`
                        }
                      />
                      <p className="text-base font-medium">
                        {org_info.org_name}
                      </p>
                    </div>
                    <p className="px-2 py-1 font-semibold text-sm">
                      {org_info.is_hidden ? "hidden" : "visible"}(
                      {org_info.scope.join(", ")})
                    </p>
                    <div className="flex gap-x-2">
                      <button
                        disabled={actions.includes(`${org_info.pk}|admin`)}
                        className="bg-black text-sm disabled:bg-slate-50 font-semibold px-3 py-1 rounded-lg text-white"
                        onClick={() => handleModify(org_info.pk,newsletterTypeId,"admin",org_info.org_name)}
                      >
                        Mark Only Admin
                      </button>
                      <button
                        disabled={actions.includes(`${org_info.pk}|user`)}
                        className="bg-black text-sm font-semibold disabled:bg-slate-50 px-3 py-1 rounded-lg text-white"
                        onClick={() => handleModify(org_info.pk,newsletterTypeId,"user",org_info.org_name)}
                      >
                        Mark Only User
                      </button>
                      <button
                        disabled={actions.includes(`${org_info.pk}|hide`) || actions.includes(`${org_info.pk}|visible`)}
                        className="bg-black text-sm font-semibold px-3 disabled:bg-slate-50 py-1 rounded-lg text-white"
                        onClick={() => !org_info.is_hidden ? handleModify(org_info.pk,newsletterTypeId,"hide",org_info.org_name) : handleModify(org_info.pk,newsletterTypeId,"visible",org_info.org_name)}
                      >
                        Mark {!org_info.is_hidden ? "hidden" : "visible"}
                      </button>
                    </div>
                  </div>
                );
              })}

              <Paginator
                data={data}
                loading={isLoading}
                setPage={setPage}
                setIntermidiatePageNumber={setIntermidiatePageNumber}
                intermidiatePageNumber={intermidiatePageNumber}
                setPageSize={setPageSize}
                pageSize={pageSize}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsletterAccessDetailPage;
