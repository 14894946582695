import React, { useState } from "react";
import Input from "../../components/input";
import { MdPlusOne } from "react-icons/md";
import { BsPlus, BsX } from "react-icons/bs";
import { FaCross } from "react-icons/fa";
import { GoX } from "react-icons/go";
import ReactSelect from "react-select";
import { CountryList } from "../../helper";
import useAxiosInstance from "../../hooks/useAxios";
import JsonView from "@uiw/react-json-view";
import { toast } from "react-toastify";

const saveTemplateAsFile = (filename, dataObjToWrite) => {
  const blob = new Blob([JSON.stringify(dataObjToWrite)], {
    type: "text/json",
  });
  const link = document.createElement("a");

  link.download = filename;
  link.href = window.URL.createObjectURL(blob);
  link.dataset.downloadurl = ["text/json", link.download, link.href].join(":");

  const evt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });

  link.dispatchEvent(evt);
  link.remove();
};

const MarketMapSearch = () => {
  const axiosInstance = useAxiosInstance();
  const [selectedTab, setSelectedTab] = useState("MM Query Results");
  const [loading, setloading] = useState(false);

  const [formData, setFormData] = useState({
    query: "",
    countries: [],
    alternate_keywords: [],
    custom_companies_domains: [],
    description: "",
    generate_alternate_keywords: true,
  });

  const [formDataV1, setFormDataV1] = useState({
    industry_definition: "",
    query: "",
    countries: [],
    limit: 100,
  });

  const [formDataV2, setFormDataV2] = useState({
    report_id:0
  });

  const [alternateKeywords, setAlternateKeywords] = useState("");
  const [customCompanyDomain, setCustomCompanyDomain] = useState("");
  const [response, setResponse] = useState([]);

  const handleMMQueryResult = () => {
    setloading(true);
    axiosInstance.post("/assets/get_mm_query_result/", formData).then((res) => {
      setloading(false);
      setResponse(res.data);
    }).catch(res=>{
      toast.error('No data')
    });
    return null;
  };

  const handleProductEmbedding = () => {
    setloading(true);
    axiosInstance.post("/assets/get_product_summary_embedding/", {...formDataV1,limit:String(formDataV1.limit)}).then((res) => {
      setloading(false);
      setResponse(res.data);
    }).catch(res=>{
      toast.error('No data')
    });
    return null;
  };
  return (
    <div className="p-3">
      <p className="bg-white px-3 py-2 border rounded text-lg font-semibold">
        Market Map Search
      </p>
      <div className="flex gap-x-2 mt-4">
        {[
          "MM Query Results",
          "Product Summary Embedding",
          "Competitive Landscape",
        ].map((bt) => {
          return (
            <button
              key={bt}
              onClick={() => {
                setSelectedTab(bt);
              }}
              className={`px-3 py-2  bg-white border text-base font-semibold ${
                selectedTab == bt && " !bg-gray-600 !border-gray-600 text-white"
              }`}
            >
              {bt}
            </button>
          );
        })}
      </div>
      <div className="p-6 bg-white border">
        {selectedTab === "MM Query Results" && (
          <>
            <p className="text-sm font-medium mb-2">Query</p>
            <Input
              type="text"
              placeholder={"Enter query"}
              value={formData.query}
              onChange={(e) => {
                setFormData({ ...formData, query: e.target.value });
              }}
            />

            <p className="text-sm font-medium mt-4 mb-2">Description</p>
            <Input
              type="text"
              placeholder={"Enter description"}
              value={formData.description}
              onChange={(e) => {
                setFormData({ ...formData, description: e.target.value });
              }}
            />

            <p className="text-sm font-medium mb-2 mt-4">Countries</p>
            <div className="flex gap-x-2">
              <ReactSelect
                className="w-full"
                options={CountryList.map((elem) => ({
                  label: elem,
                  value: elem,
                }))}
                onChange={(e) => {
                  setFormData((prev) => {
                    return { ...prev, countries: [...prev.countries, e.value] };
                  });
                }}
              />
            </div>

            <div className="bg-gray-300 mt-2 flex gap-2 p-2">
              {formData.countries.map((el) => {
                return (
                  <div
                    key={el}
                    className="px-2 bg-white border w-max py-1 flex items-center gap-2 text-sm font-semibold"
                  >
                    {el}{" "}
                    <GoX
                      className="text-base cursor-pointer font-bold"
                      onClick={() => {
                        setFormData((prev) => {
                          return {
                            ...prev,
                            countries: prev.countries.filter((it) => it !== el),
                          };
                        });
                      }}
                    />
                  </div>
                );
              })}
            </div>

            <p className="text-sm font-medium mb-2 mt-4">Alternate keywords</p>
            <div className="flex gap-x-2">
              <Input
                type="text"
                placeholder={"Enter alternateKeywords"}
                value={alternateKeywords}
                onChange={(e) => {
                  setAlternateKeywords(e.target.value);
                }}
              />
              <button
                onClick={() => {
                  setFormData((prev) => {
                    return {
                      ...prev,
                      alternate_keywords: [
                        ...prev.alternate_keywords,
                        alternateKeywords,
                      ],
                    };
                  });
                  setAlternateKeywords("");
                }}
                className="bg-black text-white p-2 font-bold rounded text-base"
              >
                <BsPlus className="h-4" />
              </button>
            </div>
            <div className="bg-gray-300 mt-2 flex gap-2 p-2">
              {formData.alternate_keywords.map((el) => {
                return (
                  <div
                    key={el}
                    className="px-2 bg-white border w-max py-1 flex items-center gap-2 text-sm font-semibold"
                  >
                    {el}{" "}
                    <GoX
                      className="text-base cursor-pointer font-bold"
                      onClick={() => {
                        setFormData((prev) => {
                          return {
                            ...prev,
                            alternate_keywords: prev.alternate_keywords.filter(
                              (it) => it !== el
                            ),
                          };
                        });
                      }}
                    />
                  </div>
                );
              })}
            </div>

            <p className="text-sm font-medium mb-2 mt-4">
              Custom Company Domain
            </p>
            <div className="flex gap-x-2">
              <Input
                type="text"
                placeholder={"Enter domain"}
                value={customCompanyDomain}
                onChange={(e) => {
                  setCustomCompanyDomain(e.target.value);
                }}
              />
              <button
                onClick={() => {
                  setFormData((prev) => {
                    return {
                      ...prev,
                      custom_companies_domains: [
                        ...prev.custom_companies_domains,
                        customCompanyDomain,
                      ],
                    };
                  });
                  setCustomCompanyDomain("");
                }}
                className="bg-black text-white p-2 font-bold rounded text-base"
              >
                <BsPlus className="h-4" />
              </button>
            </div>
            <div className="bg-gray-300 mt-2 flex gap-2 p-2">
              {formData.custom_companies_domains.map((el) => {
                return (
                  <div
                    key={el}
                    className="px-2 bg-white border w-max py-1 flex items-center gap-2 text-sm font-semibold"
                  >
                    {el}{" "}
                    <GoX
                      className="text-base cursor-pointer font-bold"
                      onClick={() => {
                        setFormData((prev) => {
                          return {
                            ...prev,
                            custom_companies_domains:
                              prev.custom_companies_domains.filter(
                                (it) => it !== el
                              ),
                          };
                        });
                      }}
                    />
                  </div>
                );
              })}
            </div>
            <p className="flex gap-x-3 font-semibold items-center mt-4">
              Generate alternate keywords :{" "}
              <div className="flex gap-x-2">
                <button
                  onClick={() => {
                    setFormData((prev) => ({
                      ...prev,
                      generate_alternate_keywords:
                        !prev.generate_alternate_keywords,
                    }));
                  }}
                  className={
                    "px-2 py-2 rounded-md bg-white border text-base font-semibold "
                  }
                >
                  {formData.generate_alternate_keywords
                    ? "Turn off"
                    : "Turn on"}
                </button>
              </div>
            </p>
          </>
        )}
        {selectedTab === "Product Summary Embedding" && (
          <>
            <p className="text-sm font-medium mb-2">Query</p>
            <Input
              type="text"
              placeholder={"Enter query"}
              value={formDataV1.query}
              onChange={(e) => {
                setFormDataV1({ ...formDataV1, query: e.target.value });
              }}
            />

            <p className="text-sm font-medium mt-4 mb-2">Industry definition</p>
            <Input
              type="text"
              placeholder={"Enter industry_definition"}
              value={formDataV1.industry_definition}
              onChange={(e) => {
                setFormDataV1({
                  ...formDataV1,
                  industry_definition: e.target.value,
                });
              }}
            />
            <p className="text-sm font-medium mt-4 mb-2">Limit</p>
            <Input
              type="number"
              placeholder={"Enter industry_definition"}
              value={formDataV1.limit}
              onChange={(e) => {
                setFormDataV1({ ...formDataV1, limit: Number(e.target.value) });
              }}
            />

            <p className="text-sm font-medium mb-2 mt-4">Countries</p>
            <div className="flex gap-x-2">
              <ReactSelect
                className="w-full"
                options={CountryList.map((elem) => ({
                  label: elem,
                  value: elem,
                }))}
                onChange={(e) => {
                  setFormDataV1((prev) => {
                    return { ...prev, countries: [...prev.countries, e.value] };
                  });
                }}
              />
            </div>

            <div className="bg-gray-300 mt-2 flex gap-2 mb-4 p-2">
              {formDataV1.countries.map((el) => {
                return (
                  <div
                    key={el}
                    className="px-2 bg-white border w-max py-1 flex items-center gap-2 text-sm font-semibold"
                  >
                    {el}{" "}
                    <GoX
                      className="text-base cursor-pointer font-bold"
                      onClick={() => {
                        setFormDataV1((prev) => {
                          return {
                            ...prev,
                            countries: prev.countries.filter((it) => it !== el),
                          };
                        });
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}
        {selectedTab === "Competitive Landscape" && <>
          <p className="text-sm font-medium mb-2">Report Id</p>
            <Input
              type="text"
              placeholder={"Enter report id"}
              value={formDataV2.report_id}
              onChange={(e) => {
                setFormDataV2({ ...formDataV2, report_id: e.target.value });
              }}
            />
            <div className="mt-4"></div>
        </>}

        <button
          onClick={() => {
            if(selectedTab === "MM Query Results"){
              handleMMQueryResult();
            }
            if(selectedTab === "Product Summary Embedding"){
              handleProductEmbedding()
            }
            if(selectedTab === "Competitive Landscape"){
              setloading(true);
              axiosInstance.get(`/assets/get_competitive_landscape_data/?report_id=${formDataV2.report_id}`).then((res) => {
                setloading(false);
                setResponse(res.data);
              }).catch(res=>{
                toast.error('No data')
              })
            }
          }}
          disabled={loading}
          className="px-8 py-2 disabled:bg-gray-300 bg-black rounded-lg text-white font-semibold"
        >
          Fire
        </button>
        <div
          onClick={() => {
            saveTemplateAsFile("response.json", response);
          }}
          className="flex justify-end w-full cursor-pointer items-center gap-2"
        >
          Download Response
        </div>
        <div className="mt-5 max-h-[60vh] overflow-y-auto">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <JsonView
              shortenTextAfterLength={0}
              value={response}
              displayObjectSize={false}
              displayDataTypes={false}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MarketMapSearch;
