import React, { useEffect, useState } from "react";
import useAxiosInstance from "../../hooks/useAxios";
import FilterModal from "../../components/FilterModal";
import { Paginator } from "../../components/Paginator";
import { Table } from "flowbite-react";
import { TableCustom } from "../../components/Table";
import { Filter1Outlined } from "@mui/icons-material";
import { FaFilter } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { NOTIFICATION_TABLE_KEYS } from "../../helper";
import { toast } from "react-toastify";

const AllNotification = () => {
    const axiosInstance = useAxiosInstance(); 
    const history = useNavigate();
    const [page, setPage] = useState(1)
    const [intermidiatePageNumber, setIntermidiatePageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(50)
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [selectedOrgList, setSelectedOrgList] = useState({})
    const [selectedUserList, setSelectedUserList] = useState({})
    const [archived, setArchived] = useState(false);
    const [showInRecent, setShowInRecent] = useState(false);
    
    
    const todays = () => {
        const date = new Date();
        const dateFormattedDate = `${date.getFullYear()+1}-${(
          "0" +
          (date.getMonth() + 1)
        ).slice(-2)}-${("0" + date.getDate()).slice(-2)}`;
        return dateFormattedDate;
      };

    
    const [modal, setModal] = useState(false);
    const [filterTimeRange, setFilterTimeRange] = useState({
        startDate: "1900-01-01",
        endDate: null,
    });
    
    useEffect(() => {
        const selectedOrgListIds = Object.keys(selectedOrgList).map((elem) => Number(elem));
        const selectedUserListIds = Object.keys(selectedUserList).map((elem) => Number(elem));
        setLoading(true);
        const controller = new AbortController();
        axiosInstance
          .post(
            `/accounts/get_all_notifications/`,
            {
                "type__code":"",
                "report_id":"",
                "archived":archived,
                "show_in_recent":showInRecent,
                "start_date":filterTimeRange.startDate,
                "end_date":filterTimeRange.endDate,
                "user":selectedUserListIds,
                "org_name":selectedOrgListIds,
                "page":page,
                "page_size":pageSize
            }
          ,{
            signal: controller.signal
          }
          )
          .then((res) => {
            setData(res.data);
            setLoading(false);
            setIntermidiatePageNumber(res.data.current_page)
          })
          .catch((err) => {
            console.log(err);
          });
        
        return () => {
          controller.abort();
        };  
      }, [page, selectedOrgList, selectedUserList, archived, filterTimeRange, pageSize, showInRecent]);
    
      
    return (
        <div className="p-2 overflow-x-auto bg-slate-100 mt-4 rounded-xl">
            <FilterModal
                filterTimeRange={filterTimeRange}
                modal={modal}
                selectedOrgList={selectedOrgList}
                selectedUserList={selectedUserList}
                setFilterTimeRange={setFilterTimeRange}
                setModal={setModal}
                setReportListPageNumber={setPage}
                setSelectedOrgList={setSelectedOrgList}
                setSelectedUserList={setSelectedUserList}
            />

            <div className="flex justify-between">
                <span className="text-xl font-medium">All Notification</span> 
                <div className="flex items-center gap-x-2">
                <button className="text-sm bg-black py-1 px-2 rounded-lg text-white"  onClick={()=>history('/addNotification')}>Add Notification</button>    
                <button onClick={()=>setModal(true)}><FaFilter/></button>
                </div>
            </div>

            <TableCustom
                columns={NOTIFICATION_TABLE_KEYS}
                data={data}
                loading={loading}
                handleEdit={(id)=>{
                    history(`/EditNotification?notification_id=${id}`)
                }}
                handleDelete={(id)=>{
                    axiosInstance.post(`/accounts/mark_archived/`,{id}).then(res=>{
                        if(res.status===200){
                            toast.success(
                                'Marked as archived!'
                            )
                        }else{
                            toast.error('Failed!')
                        }
                    }).catch(err=>{
                        toast.error(
                            'Failed!'
                        )
                    })
                }}
            />
            <Paginator
                data={data}
                loading={loading}
                setPage={setPage}
                setIntermidiatePageNumber={setIntermidiatePageNumber}
                intermidiatePageNumber={intermidiatePageNumber}
                setPageSize={setPageSize}
                pageSize={pageSize}
            />
        </div>
    );
};
export default AllNotification;
