import { Delete, Edit } from "@mui/icons-material";
import React from "react";

export const TableCustom=({columns,loading,data,handleEdit,handleDelete})=>{
    return <div className='overflow-x-auto bg-slate-100 mt-4 j-[90vh] max-h-[90vh] rounded-xl'> 
            <table className="w-full relative bg-slate-50 ">
                <thead className="sticky top-0 bg-white">
                    {columns.map((col) => (
                        <th
                            key={col.key}
                            className={`px-2 py-1 text-center w-[${col.width}]`}
                        >
                            <p className="flex items-center justify-left  text-xm font-medium mx-2 my-1 gap-x-1 text-center">
                                {col.label}
                            </p>
                        </th>
                    ))}
                        <th
                            className={`px-2 py-1 text-center`}
                        >
                            <p className="flex items-center justify-left  text-xm font-medium mx-2 my-1 gap-x-1 text-center">
                                Action
                            </p>
                        </th>
                </thead>
                <tbody className="mt-4 divide-y">
                    {loading ? <p>Loading...</p>:
                    data?.data?.map((dt) => {
                        return (
                            <tr
                                key={dt.id}
                                className="p-2 rounded-xl border-b last:border-b-0 odd:bg-slate-100 even:bg-slate-50"
                            >
                                {columns.map((col) => (
                                    <td key={col.key} className="px-2 py-1">
                                        <p className="flex justify-start  w-full">
                                            <span className="font-medium text-xs">
                                            {`${dt[col["key"]]}`}{" "}</span>
                                        </p>
                                    </td>
                                ))}
                                <td  className="px-2 py-1">
                                        <p className="flex justify-start  w-full">
                                            <span className="font-medium text-base">
                                                <Edit className="cursor-pointer" onClick={()=>handleEdit(dt.id)}/>
                                                <Delete className="cursor-pointer" onClick={()=>handleDelete(dt.id)}/>
                                            </span>
                                        </p>
                                </td>
                            </tr>   
                        );
                    })}
                </tbody>
            </table>
            </div>
}