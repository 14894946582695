import React, { useEffect, useState } from "react";
import OpenAI from "openai";
import { FaCopy } from "react-icons/fa";
import { toast } from "react-toastify";
import Input from "../../components/input";
import useAxiosInstance from "../../hooks/useAxios";
import Slider from "@mui/material/Slider";
import JsonView from "@uiw/react-json-view";

const Playground = () => {
  const [prompt, setPrompt] = useState("");
  const [promptId, setPromptId] = useState(0);
  
  const [modelType, setModelType] = useState("");
  const [modelTypes, setModelTypes] = useState([]);
  const [temperature, setTemperature] = useState(1);
  const [maxTokens, setMaxTokens] = useState(100);
  const [topP, setTopP] = useState(0);
  const [frequencyPenalty, setFrequencyPenalty] = useState(0);
  const [presencePenalty, setPresencePenalty] = useState(0);
  const [engine, setEngine] = useState({});
  const [numResponses, setNumResponses] = useState(1);
  const [systemMessage, setSystemMessage] = useState(
    "You are a helpful assistant."
  );
  const [stop, setStop] = useState(null);
  const [functions, setFunctions] = useState(null);
  const [response, setResponse] = useState({});
  const [promptOutputLoading, setPromptOutputLoading] = useState(false);
  const [viewMode, setViewMode] = useState("simple");
  const [engines, setEngines] = useState([]);
  const [loading, setLoading] = useState(false);

  const axios = useAxiosInstance();
  useEffect(() => {
    setLoading(true);
    axios.get("/assets/get_all_engines/").then((res) => {
      setEngines(res.data.data);
      setModelTypes(res.data.models_types)
      setLoading(false);
    });
  }, []);

  const handleFire = () => {
    setPromptOutputLoading(true);
    axios
      .post("/assets/view_fire_f_get_ai_results/", {
        prompt: prompt,
        prompt_id:promptId,
        temperature: temperature,
        max_tokens: maxTokens,
        ai_models:modelType,
        top_p: topP,
        frequency_penalty: frequencyPenalty,
        presence_penalty: presencePenalty,
        engine: engine.name,
        num_responses: numResponses,
        stop: stop,
        system_message: systemMessage,
        function: functions ? JSON.parse(functions):null,
      })
      .then((res) => {
        setResponse(res.data.data);
        setPromptOutputLoading(false);
      })
      .catch((r) => {
        setPromptOutputLoading(false);
        toast.error("Something went wrong while firing prompt");
      });
  };

  if (loading) {
    return <p className="p-6">Loading...</p>;
  }

  const TextWithBreakLine = ({ textWithNewlines }) =>
    textWithNewlines.split("\n").map((line, index) => {
      return (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      );
    });

  const parseJson = (response)=>{
    if (typeof response?.l1_response == 'object'){
      return response}
    try {
      return JSON.parse(response?.l1_response)    
    } catch (error) {
      toast.info('Invalid json try shifting to simple or other view mode')
      return {}
    }
  }  
  return (
    <div>
      <div className="mr-4  p-3 my-5 border rounded-lg bg-white">
        <p className="text-2xl text-black font-medium mb-4">Playground</p>
        <div className="flex gap-x-4">
          <div className="w-[70%] rounded-xl bg-gray-100 p-3">
            <p className="text-sm font-semibold text-black mb-2">
              System message
            </p>
            <div>
              <Input
                type={"text"}
                value={systemMessage}
                onChange={(e) => {
                  setSystemMessage(e.target.value);
                }}
              />
            </div>
            <p className="text-sm font-semibold text-black mt-4 mb-2">Prompt</p>
            <div>
              <Input
                type={"text__area"}
                value={prompt}
                onChange={(e) => {
                  setPrompt(e.target.value);
                }}
              />
            </div>
            <button
              disabled={promptOutputLoading}
              onClick={()=>handleFire()}
              className="px-4 py-2 rounded text-sm bg-black font-medium text-white disabled:bg-[#b6b6b6]"
            >
              Fire
            </button>

            <div className="flex justify-end gap-x-2 items-center">
              <p className="text-sm font-semibold text-black mt-4 mb-2">
                View mode
              </p>
              <select
                className="border rounded-lg px-3 py-2 w-[200px] focus:ring-0 focus:outline-gray-400 "
                value={viewMode}
                onChange={(e) => {
                  setViewMode(e.target.value);
                }}
              >
                <option value={"html"}>Html</option>
                <option value={"simple"}>Simple</option>
                {/* <option value={"\n"}>\n formatted</option> */}
                <option value={"json"}>Json</option>
                <option value={"raw_json"}>Raw Json</option>
              </select>
            </div>

            {promptOutputLoading ? (
              <p className="bg-white border rounded p-3 mt-3">Loading...</p>
            ) : (
              <div className="bg-white border rounded p-3 mt-3">
                {viewMode == "html" && (
                  <div
                    dangerouslySetInnerHTML={{ __html: response?.l1_response }}
                  />
                )}
                {viewMode == "raw_json" && (
                  <JsonView
                  shortenTextAfterLength={0}
                  value={response}
                  displayObjectSize={false}
                  displayDataTypes={false}
                />
                )}
                {viewMode == "simple" && <p>{JSON.stringify(response?.l1_response)}</p>}
                {viewMode == "json" && (
                  <JsonView
                    shortenTextAfterLength={0}
                    value={parseJson(response)}
                    displayObjectSize={false}
                    displayDataTypes={false}
                  />
                )}
              </div>
            )}
          </div>


          <div className="w-[30%] rounded-xl bg-gray-100  p-3">
          <p className="text-sm font-semibold mb-2 ">Model Type</p>
            <select
              value={modelType}
              onChange={(e) => {
                setModelType(e.target.value);
              }}
              className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 "
            >
              {[
                { name: "choose", pk: -1 },
                ...modelTypes,
              ].map((engine) => {
                return (
                  <option key={engine.pk} value={engine.name}>
                    {engine.name}
                  </option>
                );
              })}
            </select>
            <p className="text-sm font-semibold mb-2 ">Model</p>
            <select
              value={engine.name}
              onChange={(e) => {
                setEngine(engines.find((it) => it.name === e.target.value));
              }}
              className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 "
            >
              {[
                { name: "choose", pk: -1 },
                ...engines,
              ].map((engine) => {
                return (
                  <option key={engine.pk} value={engine.name}>
                    {engine.name}
                  </option>
                );
              })}
            </select>

            <p className="text-sm w-full font-semibold mb-2 mt-4 flex justify-between">
              <span>Temperature</span> <span>{temperature}</span>
            </p>
            <div className="px-3">
              <Slider
                color="info"
                min={0}
                step={0.05}
                max={1}
                value={temperature}
                onChange={(event, newValue) => {
                  setTemperature(Number(newValue));
                }}
              />
            </div>

            <p className="text-sm w-full font-semibold mb-2 mt-4 flex justify-between">
              <span>Max Token</span>
            </p>
            <Input
              color="info"
              value={maxTokens}
              type={"number"}
              onChange={(event) => {
                setMaxTokens(Number(event.target.value));
              }}
            />
            <p className="text-sm w-full font-semibold mb-2 mt-4 flex justify-between">
              <span>Prompt ID</span>
            </p>
            <Input
              color="info"
              value={promptId}
              type={"number"}
              onChange={(event) => {
                setPromptId(Number(event.target.value));
              }}
            />

            <p className="text-sm w-full font-semibold mb-2 mt-4 flex justify-between">
              <span>Function(valid json)</span>
            </p>
            <Input
              color="info"
              value={functions}
              onChange={(event) => {
                setFunctions(event.target.value);
              }}
            />

            <p className="text-sm w-full font-semibold mb-2 mt-4 flex justify-between">
              <span>Top P</span> <span>{topP}</span>
            </p>
            <div className="px-3">
              <Slider
                color="info"
                max={1}
                min={0}
                step={0.05}
                value={topP}
                type={"number"}
                onChange={(event, num) => {
                  setTopP(Number(num));
                }}
              />
            </div>

            <p className="text-sm w-full font-semibold mb-2 mt-4 flex justify-between">
              <span>Frequency Penalty</span> <span>{frequencyPenalty}</span>
            </p>
            <div className="px-3">
              <Slider
                color="info"
                max={1}
                min={0}
                step={0.05}
                value={frequencyPenalty}
                type={"number"}
                onChange={(event, num) => {
                  setFrequencyPenalty(Number(num));
                }}
              />
            </div>

            <p className="text-sm w-full font-semibold mb-2 mt-4 flex justify-between">
              <span>Presence Penalty</span> <span>{presencePenalty}</span>
            </p>
            <div className="px-3">
              <Slider
                color="info"
                max={1}
                min={0}
                step={0.05}
                value={presencePenalty}
                type={"number"}
                onChange={(event, num) => {
                  setPresencePenalty(Number(num));
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Playground;
