import React, { useEffect, useState } from 'react'
import useAxiosInstance from '../../hooks/useAxios';
import { useNavigate } from 'react-router-dom';

const SeeAllNewsletterAccess = () => {
  const axiosInstance = useAxiosInstance(); 
  const [isLoading, setIsLoading] = useState(false);
  const [allNewsletterTypes,setAllNewsletterTypes] = useState([]);
  const history = useNavigate()

  useEffect(() => {
      setIsLoading(true);
      axiosInstance.get(`/newsletter/v2/all_newsletters_type/`).then((res) => {
        setAllNewsletterTypes(res.data.data);
        setIsLoading(false);
      });
    }, []);  
  return (
    <div className="min-h-screen">
    <div className="p-6">
      <div className="mb-4">
        <div className="text-2xl font-semibold p-3 border flex justify-between bg-white rounded-xl">Newsletter Access <button
        onClick={()=>{history('/bulkChangesNewsletterAccess')}}
        className='px-3 py-2 bg-black text-white rounded-lg text-xs font-semibold'>Bulk Add/Edit</button></div>
        {isLoading?<p className='mt-5'>Loading....</p>:
        <div className='grid grid-cols-3 gap-3 mt-5'>
            {allNewsletterTypes.map(it=>{return <div key={it.pk} onClick={()=>history(
                `/newsletterTypeAccessDetailPage?type_id=${it.pk}&type_name=${it.name}`
            )} className='p-3 bg-white gap-x-4 flex flex-col  border rounded-xl cursor-pointer'>
                    <img src={it.icon} className='max-h-[100px]' alt=''/> <div><p className='text-sm font-semibold'>{it.name}</p>
                    <p className='text-sm'>{it.desc}</p>
                    </div>
                </div>
            })}
        </div>}    
        </div>
        </div>
    </div>
  )
}

export default SeeAllNewsletterAccess