import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useAxiosInstance from "../../hooks/useAxios";
import FormRender from "../../components/FormRender";
import { workflowCardsMetaData } from "../DashboardTiles";
import Select from "react-select";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import { Close, Delete, Edit } from "@mui/icons-material";

const PreferencesDetailedView = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const user_id = searchParams.get("user_id");
  const org_name = searchParams.get("org_name");
  const [dashboardCardLoading, setDashboardCardLoading] = useState(false);
  const [dashboardCards, setDashboardCardCards] = useState({});
  const axiosInstance = useAxiosInstance();
  const [dashboardTiles, setDashboardTiles] = useState([]);
  const [selectPins, setSelectedPins] = useState([]);
  const [editPinnedWorkflow, setEditPinnedWorkflow] = useState(false);
  // const [isLoading,setIsLoading] = useState(false)

  const [notificationData, setNotificationData] = useState({});
  const [userPreferences, setUserPreferences] = useState({});

  const [notificationDataLoading, setNotificationDataLoading] = useState(false);
  const [userPreferencesLoading, setUserPreferencesLoading] = useState(false);
  const [userPreferencesRetrigger, setUserPreferencesRetrigger] = useState(0);
  const [userPreferencesSaving, setUserPreferencesSaving] = useState(false);
  const [preferedSources, setPreferedSources] = useState([]);
  const [nonPreferedSources, setNonPreferedSources] = useState([]);

  const [preferred_notebook_language, setPreferredNotebookLanguage] =
    useState("EN");
  const languageOptions = [
    {
      label: "English",
      value: "EN",
    },
    {
      label: "French",
      value: "FR",
    },
    {
      label: "German",
      value: "DE",
    },
    {
      label: "Hebrew",
      value: "IW",
    },
    {
      label: "Japanese",
      value: "JA",
    },
    {
      label: "Korean",
      value: "KO",
    },
    {
      label: "Mandarin Chinese",
      value: "zh-CN",
    },
    {
      label: "Spanish",
      value: "ES",
    },
    {
      label: "Arabic",
      value: "AR",
    },
  ];
  //   const [formSetting, setFormSetting] = useState([]);
  useEffect(() => {
    setNotificationDataLoading(true);
    axiosInstance
      .get(`/accounts/notification-preferences/?user__id=${user_id}`)
      .then((res) => {
        setNotificationData(res.data);
        console.log(res.data);
        setNotificationDataLoading(false);
        // setFormSetting(makeFormSettings(res.data))
      })
      .catch((ee) => console.log(ee));
  }, [user_id]);

  useEffect(() => {
    setUserPreferencesLoading(true);
    axiosInstance
      .get(`/accounts/user-preferences/?user__id=${user_id}`)
      .then((res) => {
        setUserPreferences(res.data);
        setPreferredNotebookLanguage(
          res.data.preferred_notebook_language
            ? res.data.preferred_notebook_language
            : "EN"
        );
        setUserPreferencesLoading(false);
        setPreferedSources(
          (res?.data?.data_sources?.preferred || []).map((it) => ({
            id: uuid(),
            value: it,
          }))
        );
        setNonPreferedSources(
          (res?.data?.data_sources?.non_preferred || []).map((it) => ({
            id: uuid(),
            value: it,
          }))
        );
        console.log(res.data);
      })
      .catch((ee) => console.log(ee));
  }, [user_id, userPreferencesRetrigger]);

  useEffect(() => {
    setDashboardCardLoading(true);
    axiosInstance
      .get(`accounts/sandbox-dashboard/?org=${org_name}`)
      .then((res) => {
        setDashboardCardCards(res.data);
        setDashboardCardLoading(false);
        // dashboardCards.filter(c => !c.subscription.includes('Hidden')).filter((_, idx) => idx < 4)
      })
      .catch((err) => {
        setDashboardCardLoading(false);
      });
  }, [org_name]);

  useEffect(() => {
    const data = dashboardCards[searchParams.get("user_type")]?.data;
    const features = dashboardCards[searchParams.get("user_type")]?.features;
    if (data && features) {
      data?.forEach((tile) => (tile.tileType = "report"));
      if (features && Object.keys(features)?.length) {
        const additionalTiles = Object.values(features);
        const codes = additionalTiles.map((e) => e.code);
        const alreadyInCards = data.find((e) => codes.includes(e.code));
        if (alreadyInCards) {
          alreadyInCards.tileType = "feature";
        } else {
          additionalTiles.forEach((tile) => {
            tile.tileType = "feature";
          });
          data.push(...additionalTiles);
        }
      }

      let cardRes = [
        ...data?.sort((a, b) => a?.order_preference - b?.order_preference),
      ];

      cardRes = cardRes?.map((card) => {
        return {
          ...card,
          ...(workflowCardsMetaData[card.code] || {}),
          url: workflowCardsMetaData[card.code]?.url
            ? workflowCardsMetaData[card.code].url
            : `/dashboard/workflows/${card.code}`,
        };
      });
      // setOrgName(overAllData.org_name)
      setDashboardTiles(
        cardRes.filter((c) => !c.subscription.includes("Hidden"))
      );
    }
  }, [dashboardCards, searchParams]);

  return (
    <div>
      <p className="text-xl font-semibold p-3 mt-5 bg-white border rounded-xl mb-3">
        User preferences
      </p>
      {notificationDataLoading ? (
        <>Notification settings loading....</>
      ) : (
        <div className="p-3 border bg-white rounded-xl">
          {notificationData?.notification_preferences?.map((notification) => {
            return (
              <div key={notification} className="flex justify-between mb-3">
                <div>
                  <p className="text-base font-semibold ">
                    {notification.type_label}
                  </p>
                  <p className="text-sm font-normal">
                    {notification.description}
                  </p>
                </div>
                <div className="flex gap-x-2 items-center">
                  <span className="text-xs font-semibold">
                    Email{" "}
                    <input
                      type="checkbox"
                      onChange={() => {
                        const enabled = !notification?.channels?.find(
                          (ch) => ch.channel_code === "EMAIL"
                        ).enabled;
                        const code = "EMAIL";

                        setNotificationData((prev) => {
                          const notificationPreferences = JSON.parse(
                            JSON.stringify(prev.notification_preferences)
                          );
                          const _notificationPreferences = [];
                          notificationPreferences.forEach((it) => {
                            const channels = [];
                            it.channels.forEach((ch) => {
                              if (ch.channel_code === code) {
                                channels.push({ ...ch, enabled: enabled });
                              } else {
                                channels.push({ ...ch });
                              }
                            });

                            if (it.type_code === notification.type_code) {
                              _notificationPreferences.push({
                                ...it,
                                channels: channels,
                              });
                            } else {
                              _notificationPreferences.push(it);
                            }
                          });
                          console.log({
                            notification_preferences: _notificationPreferences,
                          });
                          return {
                            notification_preferences: _notificationPreferences,
                          };
                        });
                      }}
                      checked={
                        notification?.channels?.find(
                          (ch) => ch.channel_code === "EMAIL"
                        ).enabled
                      }
                    />
                  </span>
                  <span className="text-xs font-semibold">
                    In-app{" "}
                    <input
                      type="checkbox"
                      onChange={() => {
                        const enabled = !notification?.channels?.find(
                          (ch) => ch.channel_code === "IN_APP"
                        ).enabled;
                        const code = "IN_APP";

                        setNotificationData((prev) => {
                          const notificationPreferences = JSON.parse(
                            JSON.stringify(prev.notification_preferences)
                          );
                          const _notificationPreferences = [];
                          notificationPreferences.forEach((it) => {
                            const channels = [];
                            it.channels.forEach((ch) => {
                              if (ch.channel_code === code) {
                                channels.push({ ...ch, enabled: enabled });
                              } else {
                                channels.push({ ...ch });
                              }
                            });

                            if (it.type_code === notification.type_code) {
                              _notificationPreferences.push({
                                ...it,
                                channels: channels,
                              });
                            } else {
                              _notificationPreferences.push(it);
                            }
                          });
                          console.log({
                            notification_preferences: _notificationPreferences,
                          });
                          return {
                            notification_preferences: _notificationPreferences,
                          };
                        });
                      }}
                      checked={
                        notification?.channels?.find(
                          (ch) => ch.channel_code === "EMAIL"
                        ).enabled
                      }
                    />
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {userPreferencesLoading ? (
        <div className="">Preference settings loading....</div>
      ) : (
        <div className="p-3 mt-4 border bg-white rounded">
          <div className="mb-4">
            <div className="flex gap-x-3 items-center w-full">
              <p className="text-base font-semibold mb-3 w-full">Preferred</p>
              <button
                onClick={() => {
                  setPreferedSources((prev) => [
                    ...prev,
                    { value: "", id: uuid() },
                  ]);
                }}
                className="px-3 py-1 bg-black rounded text-white"
              >
                add
              </button>
            </div>
            <TagRender
              axiosInstance={axiosInstance}
              nonPreferedSources={nonPreferedSources}
              preferedSources={preferedSources}
              searchParams={searchParams}
              setPreferedSources={setPreferedSources}
            />
          </div>
          <div className="mb-4 justify-between items-center">
            <div className="flex gap-x-3 items-center">
              <p className="text-base font-semibold mb-3 w-full">
                Non Preferred
              </p>
              <button
                onClick={() => {
                  setNonPreferedSources((prev) => [
                    ...prev,
                    { value: "", id: uuid() },
                  ]);
                }}
                className="px-3 py-1 bg-black rounded text-white"
              >
                add
              </button>
            </div>
            <TagRender
              axiosInstance={axiosInstance}
              nonPreferedSources={preferedSources}
              preferedSources={nonPreferedSources}
              searchParams={searchParams}
              setPreferedSources={setNonPreferedSources}
              is_invert
            />
          </div>
          <div className="flex mb-4 justify-between items-center">
            <p className="text-base font-semibold mb-3 ">Pinned workflows</p>
            <button
              onClick={() => {
                setEditPinnedWorkflow(true);
                setSelectedPins(
                  userPreferences["pinned_workflows"]
                    ?.sort((a, b) => a.order - b.order)
                    .map((i) => i.name)
                    .map((code) => ({
                      value: code,
                      label: dashboardTiles?.find((elem) => elem?.code === code)
                        ?.name,
                    }))
                );
              }}
              className="px-3 py-2 rounded-lg bg-black text-white font-semibold text-sm"
            >
              Edit
            </button>
          </div>
          {editPinnedWorkflow ? (
            <div className="flex justify-between items-center gap-x-2">
              <Select
                isLoading={dashboardCardLoading}
                isMulti
                className="w-full"
                options={dashboardTiles.map((elem) => ({
                  label: elem.name,
                  value: elem.code,
                }))}
                onChange={(e) => {
                  console.log(e);
                  setSelectedPins(e);
                }}
                value={selectPins}
              />
              <button
                onClick={() => {
                  if (!window.confirm("Are you sure ?")) {
                    return;
                  }
                  setUserPreferencesSaving(true);
                  axiosInstance
                    .post("/accounts/user-preferences/update/", {
                      user__id: Number(searchParams.get("user_id")),
                      pinned_workflows: selectPins.map((it) => it.value),
                    })
                    .then((res) => {
                      if (res.status === 200) {
                        toast.success("Marked");
                        setUserPreferencesSaving(false);
                        setUserPreferencesRetrigger((prev) => prev + 1);
                        setEditPinnedWorkflow(false);
                      }
                    })
                    .catch((err) => {
                      setUserPreferencesSaving(false);
                      setEditPinnedWorkflow(false);
                    });
                }}
                disabled={userPreferencesSaving}
                className="px-3 py-2 rounded-lg bg-black text-white font-semibold text-sm disabled:bg-slate-200 "
              >
                {userPreferencesSaving ? "saving..." : "Save and close"}
              </button>
            </div>
          ) : (
            <div className="grid grid-cols-4 gap-x-3">
              {(userPreferences["pinned_workflows"]?.length > 0
                ? userPreferences["pinned_workflows"]
                    ?.sort((a, b) => a.order - b.order)
                    .map((i) => i.name)
                : dashboardTiles
                    .filter((_, idx) => idx < 4)
                    .map((card) => card.code)
              )?.map((card) => {
                const item = dashboardTiles.find(
                  (c) => card === c?.code && !c.subscription.includes("Hidden")
                );
                const icon = item?.icon;
                const mainTitle = item?.name;
                return (
                  <div className="px-3 py-3 flex items-center gap-x-2 w-full  border rounded-lg text-base font-medium">
                    <img className="h-8" src={icon} />
                    {mainTitle}
                  </div>
                );
              })}
            </div>
          )}

          <p className="text-base font-semibold mb-3 mt-5">
            Language preferences
          </p>
          <select
            value={preferred_notebook_language}
            onChange={(e) => {
              if (!window.confirm("Are you sure?")) {
                return;
              }
              setPreferredNotebookLanguage(e.target.value);
              axiosInstance
                .post("/accounts/user-preferences/update/", {
                  user__id: Number(searchParams.get("user_id")),
                  preferred_notebook_language: preferred_notebook_language,
                })
                .then((res) => {
                  if (res.status === 200) {
                    toast.success("updated");
                  } else {
                    toast.error("failed");
                  }
                })
                .catch((err) => {
                  toast.error("failed");
                });
            }}
            className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 "
            placeholder="Enter your password"
          >
            <option value={""}>Choose</option>
            {languageOptions.map((it) => (
              <option key={it.label} value={it.value}>
                {it.label}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
};

export default PreferencesDetailedView;

const TagRender = ({
  preferedSources,
  setPreferedSources,
  axiosInstance,
  searchParams,
  nonPreferedSources,
  is_invert = false,
}) => {
  return (
    <div className="flex flex-wrap w-full">
      {preferedSources.map((it) => {
        if (it.isEditOn) {
          return (
            <div key={it.id}>
              <input
                value={it.value}
                onChange={(e) => {
                  setPreferedSources((prev) => {
                    const source = JSON.parse(
                      JSON.stringify(prev.find((item) => item.id === it.id))
                    );
                    source["value"] = e.target.value;
                    let sources = [];
                    prev.forEach((item) => {
                      if (item.id === it.id) {
                        sources.push(source);
                      } else {
                        sources.push(item);
                      }
                    });
                    return sources;
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    axiosInstance
                      .post("/accounts/user-preferences/update/", {
                        user__id: Number(searchParams.get("user_id")),
                        data_sources: {
                          preferred: is_invert
                            ? nonPreferedSources.map((it) => it.value)
                            : preferedSources.map((it) => it.value),
                          non_preferred: is_invert
                            ? preferedSources.map((it) => it.value)
                            : nonPreferedSources.map((it) => it.value),
                        },
                      })
                      .then((res) => {
                        if (res.status === 200) {
                          toast.success("updated");
                        } else {
                          toast.error("failed");
                        }
                      })
                      .catch((err) => {
                        toast.error("failed");
                      });

                    setPreferedSources((prev) => {
                      const source = JSON.parse(
                        JSON.stringify(prev.find((item) => item.id === it.id))
                      );
                      source["isEditOn"] = false;
                      let sources = [];
                      prev.forEach((item) => {
                        if (item.id === it.id) {
                          sources.push(source);
                        } else {
                          sources.push(item);
                        }
                      });
                      return sources;
                    });
                  }
                }}
              />
              <Close
                onClick={() => {
                  setPreferedSources((prev) => {
                    const source = JSON.parse(
                      JSON.stringify(prev.find((item) => item.id === it.id))
                    );
                    source["isEditOn"] = false;
                    let sources = [];
                    prev.forEach((item) => {
                      if (item.id === it.id) {
                        sources.push(source);
                      } else {
                        sources.push(item);
                      }
                    });
                    return sources;
                  });
                }}
              />
            </div>
          );
        }

        return (
          <div
            key={it.id}
            className="px-4 py-2 bg-slate-100 text-sm font-semibold flex w-max rounded-3xl"
          >
            {it.value}{" "}
            <div className="flex gap-x-[2px] ml-2 items-center">
              <Edit
              className="cursor-pointer"
                onClick={() => {
                  setPreferedSources((prev) => {
                    const source = JSON.parse(
                      JSON.stringify(prev.find((item) => item.id === it.id))
                    );
                    source["isEditOn"] = true;
                    let sources = [];
                    prev.forEach((item) => {
                      if (item.id === it.id) {
                        sources.push(source);
                      } else {
                        sources.push(item);
                      }
                    });
                    return sources;
                  });
                }}
                style={{ height: "16px" }}
              />{" "}
              <Delete
                className="cursor-pointer"
                onClick={() => {
                  setPreferedSources((prev) => {
                    let sources = [];
                    prev.forEach((item) => {
                      if (item.id === it.id) {
                      } else {
                        sources.push(item);
                      }
                    });
                    return sources;
                  });
                  axiosInstance
                    .post("/accounts/user-preferences/update/", {
                      user__id: Number(searchParams.get("user_id")),
                      data_sources: {
                        preferred: is_invert
                          ? nonPreferedSources
                              .filter((_it) => _it.id !== it.id)
                              .map((it) => it.value)
                          : preferedSources
                              .filter((_it) => _it.id !== it.id)
                              .map((it) => it.value),
                        non_preferred: is_invert
                          ? preferedSources
                              .filter((_it) => _it.id !== it.id)
                              .map((it) => it.value)
                          : nonPreferedSources
                              .filter((_it) => _it.id !== it.id)
                              .map((it) => it.value),
                      },
                    })
                    .then((res) => {
                      if (res.status === 200) {
                        toast.success("updated");
                      } else {
                        toast.error("failed");
                      }
                    })
                    .catch((err) => {
                      toast.error("failed");
                    });
                }}
                style={{ height: "16px" }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
