import React, { useEffect, useState } from "react";
import FormRender from "../../components/FormRender";
import useAxiosInstance from "../../hooks/useAxios";
import { useNavigate } from "react-router-dom";
import { NEWSLETTER_ACCESS_FORM_SETTINGS } from "../../helper";
import { toast } from "react-toastify";

const BlukAddOrEditNewsletterAccess = () => {
  const axiosInstance = useAxiosInstance();

  const [formData, setFormData] = useState({
    "org_name__pks":{},
    "is_hidden":false,
    "is_user_only":false,
    "is_admin_only":false
  });
  const [isLoading, setIsLoading] = useState(false);
  const [allNewsletterTypes, setAllNewsletterTypes] = useState([]);
  const history = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    axiosInstance.get(`/newsletter/v2/all_newsletters_type/`).then((res) => {
      setAllNewsletterTypes(res.data.data);
      setIsLoading(false);
    });
  }, []);

  const [saving,setSaving] = useState(false)

  function handleSave() {
      setSaving(true)
      const payload = 
          {
              ...formData, 
              'is_hidden':Boolean(formData.is_hidden), 
              'is_user_only':Boolean(formData.is_user_only),
              'is_admin_only':Boolean(formData.is_admin_only),
              'org_name__pks':Object.keys(formData.org_name__pks).map(it=>Number(it)),
              'newsletter_type__pk':Number(formData.newsletter_type__pk)
          }
      axiosInstance.post(`/newsletter/v2/give_mass_access_for_orgs/`,payload).then(res=>{
          if(res.status===200){
              toast.success('Successfull!')
          }else{
              toast.error('Failed!')
          }
          setSaving(false)
      }).catch(err=>{
          setSaving(false)
          toast.error('Failed!')        
      })          
    }

  return (
    <div className="min-h-screen w-full">
      <div className="p-6 w-full">
        <div className="mb-4 w-full">
          <div className="text-2xl font-semibold p-3 border bg-white rounded-xl">
            Bulk Edit/add
          </div>
        </div>
        {isLoading?'loading...':
        <div className="bg-white p-3 border rounded-xl">
          <FormRender
            formSettings={NEWSLETTER_ACCESS_FORM_SETTINGS}
            formData={formData}
            setFormData={setFormData}
            select_options={{
                newsletter_type__pk: allNewsletterTypes.map((it) => ({
                value: it.pk,
                label: it.name,
              })),
            }}
          />
          <button
          onClick={()=>{
            handleSave()
          }}
          disabled={Object.keys(formData.org_name__pks).length===0||formData?.["newsletter_type__pk"]?.length===0 || saving} className="px-3 disabled:bg-slate-100 py-1 disabled:cursor-not-allowed text-sm mt-5 bg-black text-white rounded">Save</button>
        </div>}
      </div>
    </div>
  );
};

export default BlukAddOrEditNewsletterAccess;
