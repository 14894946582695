import React, { useEffect, useState } from "react";
import FormRender from "../../components/FormRender";
import { NOTIFICATION_FORM_SETTING } from "../../helper";
import useAxiosInstance from "../../hooks/useAxios";
import { toast } from "react-toastify";

const AddNotification = () => {
  const axiosInstance = useAxiosInstance();
  const [formData, setFormData] = useState({
    date: "2999-12-31T00:00:00Z",
    viewed_at: null,
    user: {},
    archived:false,
    show_in_recent:false,
    description:""
  });
  const [allNewsletterTypes, setAllNewsletterTypes] = useState([]);
  const [saving, setSaving] = useState(false);
  
  function handleSave() {
    setSaving(true)
    const payload = 
        {
            ...formData, 
            'archived':Boolean(formData.archived), 
            'show_in_recent':Boolean(formData.show_in_recent), 
            'report_id':Number(formData.report_id),
            "type": Number(formData.type),
            "meta":JSON.parse(formData.meta||"{}")
        }
    axiosInstance.post(`/accounts/create_notification/`,payload).then(res=>{
        if(res.status===200){
            toast.success('Successfully created!')
        }else{
            toast.error('Failed!')
        }
        setSaving(false)
    }).catch(err=>{
        setSaving(false)
        toast.error('Failed!')        
    })          
  }

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    axiosInstance.get(`/accounts/get_notifications_basic_data/`).then((res) => {
      setAllNewsletterTypes(res.data.data);
      setIsLoading(false);
    });
  }, []);

  return (
    <div>
      <div className="p-6">
        <div className="text-2xl font-semibold items-center flex justify-between">
          Add Notification
        </div>
        <div className="mt-4 p-4 border rounded-lg bg-white  flex flex-col gap-y-4">
          {isLoading ? (
            <>loading...</>
          ) : (
            <FormRender
              formData={formData}
              formSettings={[...NOTIFICATION_FORM_SETTING.filter(it=>it.saveKey!=='meta'),{ label: "Meta(Valid JSON)", type: "text", saveKey: "meta" },]}
              setFormData={setFormData}
              select_options={{
                "type":
                  allNewsletterTypes.map((it) => ({
                    value: it.pk,
                    label: it.label,
                  }))
              }}

            />
          )}
          <button
            onClick={()=>handleSave()}
            disabled={formData?.title?.length===0 || Object.keys(formData?.user)?.length===0 || formData?.type?.length===0 || saving}
            className="text-sm px-3 py-2 self-end bg-black rounded-lg text-white disabled:bg-slate-100"
          >
            {saving?"Loading..." : "Add"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddNotification;