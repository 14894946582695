import React, { useEffect, useState } from "react";
import FormRender from "../../components/FormRender";
import { NOTIFICATION_FORM_SETTING } from "../../helper";
import { useSearchParams } from "react-router-dom";
import useAxiosInstance from "../../hooks/useAxios";
import { toast } from "react-toastify";

const EditNotification = () => {
  const axiosInstance = useAxiosInstance()
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    date: "2999-12-31T00:00:00Z",
    viewed_at: "2999-12-31T00:00:00Z",
    "user":{}
  });
  const [searchParams,setSearchParams] = useSearchParams()

  function handleSave() {}

  useEffect(() => {
    setLoading(true);
    axiosInstance.post('/accounts/get_notification_details/',{'id':searchParams.get('notification_id')})
    .then((res) => {
        setFormData(res.data);
        axiosInstance.get(`/accounts/get_notifications_basic_data/`).then((res) => {
              setAllNewsletterTypes(res.data.data);
              setLoading(false);
            });
    })
  },[searchParams.get('notification_id')])    


  const [allNewsletterTypes, setAllNewsletterTypes] = useState([]);
  const [saving, setSaving] = useState(false);
    
    function handleSave() {
      setSaving(true)
      const payload = 
          {
              ...formData, 
              'archived':Boolean(formData.archived),
              "user":Object.keys(formData.user)[0], 
              'show_in_recent':Boolean(formData.show_in_recent), 
              'report_id':Number(formData.report_id),
              "type": Number(formData.type),
              "meta":formData.meta,
              "id":Number(searchParams.get('notification_id'))
          }
      axiosInstance.post(`/accounts/edit_notification/`,payload).then(res=>{
          if(res.status===200){
              toast.success('Edited created!')
          }else{
              toast.error('Failed!')
          }
          setSaving(false)
      }).catch(err=>{
          setSaving(false)
          toast.error('Failed!')        
      })          
    }

    
  return (
    <div>
      <div className="p-6">
        <div className="text-2xl font-semibold items-center flex justify-between">
          Edit Notification
        </div>
        <div className="mt-4 p-4 border rounded-lg bg-white  flex flex-col gap-y-4">
          {loading?'loading....':
          <FormRender
          formData={formData}
          formSettings={[...NOTIFICATION_FORM_SETTING.filter(it=>it.saveKey!=='type'),{
            label: "Notification Type*",
            type: "readonly",
            saveKey: "type",
            options: [],
            "realValues":allNewsletterTypes
          }]}
            setFormData={setFormData}
          />}
          <button
            disabled={loading}
            onClick={handleSave}
            className="text-sm px-3 py-2 self-end bg-black rounded-lg text-white"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditNotification;