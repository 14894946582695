import React, { useEffect, useState } from "react";
import useAxiosInstance from "../hooks/useAxios";
import { toast } from "react-toastify";
import Input from "./input";
import { useDebounce } from "use-debounce";
import { ModalComp } from "./Modal";
import { FaCross } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
const FilterModal = ({
  setReportListPageNumber,
  selectedOrgList,
  setSelectedOrgList,
  selectedUserList,
  setSelectedUserList,
  filterTimeRange,
  setFilterTimeRange,
  modal,
  setModal,
}) => {
  const axiosInstance = useAxiosInstance();
  const [selectedReportType, setSelectedReportType] = useState([]);

  const [orgLoadings, setOrgLoading] = useState(false);
  const [isUserListLoading, setIsUserListLoading] = useState(false);
  const [org_id_to_details_mappnig, setOrg_id_to_details_mapping] = useState(
    {}
  );
  const [orgs, setOrgs] = useState({});
  const [searchOrg, setSeachOrg] = useState("");

  //user menu states
  const [userList, setUserList] = useState({});
  const [
    userPaginatedIntermidatePageNumber,
    setUserPaginatedIntermidatePageNumber,
  ] = useState(1);
  const [userPaginatedPageNumber, setUserPaginatedPageNumber] = useState(1);
  const [userSearch, setUserSearch] = useState("");
  const [userQuery] = useDebounce(userSearch, 1000);

  const getOrgSearchData = (searchOrg, orgs) => {
    if (searchOrg === "") {
      return orgs;
    } else {
      return orgs?.filter((item) =>
        item?.name?.toLowerCase()?.includes(searchOrg?.toLowerCase())
      );
    }
  };
  const getOrgImage = (img, name) => {
    if (img === null) {
      return `https://wklogo.blob.core.windows.net/logos-small/${name[0]?.toUpperCase()}.png`;
    } else {
      return img;
    }
  };

  useEffect(() => {
    setIsUserListLoading(true);
    let new_orgs_ids = Object.keys(selectedOrgList).map((elem) => Number(elem));
    const controller = new AbortController();
    axiosInstance
      .get(
        `/admin/get-org-users/?&pgsz=20&page=${userPaginatedPageNumber}&org_ids=${new_orgs_ids}&query=${userQuery}&report_type=${selectedReportType}`,
        {
          signal: controller.signal,
        }
      )
      .then((res) => {
        setUserList(res.data);
        setIsUserListLoading(false);
        setUserPaginatedIntermidatePageNumber(res.data.current_page);
      })
      .catch((err) => {
        console.log(err);
        // toast.error("Something went while getting users");
        // setIsUserListLoading(false);
      });

    return () => {
      controller.abort();
    };
  }, [userPaginatedPageNumber, userQuery, selectedOrgList]);

  useEffect(() => {
    setUserPaginatedPageNumber(1);
  }, [userQuery, selectedOrgList]);

  useEffect(() => {
    setReportListPageNumber(1);
  }, [selectedOrgList]);
  useEffect(() => {
    setReportListPageNumber(1);
  }, [selectedUserList]);
  

  useEffect(() => {
    setOrgLoading(true);
    axiosInstance
      .get("admin/organisations/")
      .then((res) => {
        setOrgs(res.data);
        let new_mapping = {};
        let all_orgs = res?.data?.organisations;
        all_orgs.forEach((org) => {
          new_mapping[org.org_id] = org;
        });
        setOrgLoading(false);
        // setcompanyRequestPaginatedPageNumber(1)
        setOrg_id_to_details_mapping(new_mapping);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went while getting users");
        setOrgLoading(false);
      });
  }, []);

  return (
    <div>
      <ModalComp
        width={"800px"}
        open={modal}
        onClose={() => {
          setModal(false);
        }}
      >
        <div className="rounded-xl  bg-white border min-w-[800px]">
          <div className="flex justify-between">
          <p className="text-base bg-white my-1 rounded-md text-black px-3 font-semibold">
            Filter Menu
          </p>
          <button onClick={()=>{setModal(false)}} className="p-2 bg-slate-100 text-sm w-max rounded-lg"><IoMdClose/></button> 
          </div>
          <div className="w-full py-3 pl-3 pr-1 mr-1 h-[80vh] overflow-y-auto">
            <div className=" bg-white w-full rounded-lg border">
              <Input
                value={searchOrg}
                onChange={(e) => {
                  setSeachOrg(e.target.value);
                }}
                type="text"
                placeholder="Search Orgs"
              />
              <div className="overflow-y-auto h-36 mt-2 bg-slate-100 border p-1 rounded-lg w-full">
                <table className="w-full ">
                  <thead className="border-b text-left">
                    <th className="px-2 py-1 text-gray-700 text-sm ">Select</th>
                    <th className="px-2 py-1 text-gray-700 text-sm ">
                      Org Logo
                    </th>
                    <th className="px-2 py-1 text-gray-700 text-sm ">Name</th>
                    <th className="px-2 py-1 text-gray-700 text-sm ">Label</th>
                    <th className="px-2 py-1 text-gray-700 text-sm ">
                      Account Type
                    </th>
                  </thead>
                  <tbody className="divide-y">
                    {orgLoadings ? (
                      <div className="flex justify-center flex-col items-center">
                        <p>Loading...</p>
                      </div>
                    ) : (
                      getOrgSearchData(
                        searchOrg,
                        orgs?.organisations || []
                      ).map((org) => {
                        return (
                          <tr
                            className="cursor-pointer"
                            onClick={() => {
                              let org_lists = Object.keys(selectedOrgList);
                              let old_org_list = JSON.parse(
                                JSON.stringify(selectedOrgList)
                              );
                              if (org_lists.includes(String(org.org_id))) {
                                delete old_org_list[org.org_id];
                              } else {
                                old_org_list[org.org_id] = org;
                              }
                              setSelectedOrgList(old_org_list);
                            }}
                            key={org.org_id}
                          >
                            <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                              <input
                                type="checkbox"
                                readOnly
                                checked={Object.keys(selectedOrgList).includes(
                                  String(org?.org_id)
                                )}
                              />
                            </td>
                            <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                              {
                                <div
                                  style={{
                                    backgroundImage: `url('${getOrgImage(
                                      org?.org_img,
                                      org?.name
                                    )}')`,
                                  }}
                                  className="h-8 w-8 bg-center bg-no-repeat bg-contain"
                                />
                              }
                            </td>
                            <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                              {org?.name}
                            </td>
                            <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                              {org?.label}
                            </td>
                            <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                              {org?.account_type?.name}
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="p-3 mt-2 bg-white w-full rounded-lg border">
              <Input
                value={userSearch}
                onChange={(e) => {
                  setUserSearch(e.target.value);
                }}
                type="text"
                placeholder="Search User"
              />
              <div className="overflow-y-auto h-36 mt-2 bg-slate-100 border p-1 rounded-lg w-full">
                <table className="w-full ">
                  <thead className="border-b text-left">
                    <th className="px-2 py-1 text-gray-700 text-sm ">Select</th>
                    <th className="px-2 py-1 text-gray-700 text-sm ">
                      UserName
                    </th>
                    <th className="px-2 py-1 text-gray-700 text-sm ">
                      First Name
                    </th>
                    <th className="px-2 py-1 text-gray-700 text-sm ">
                      Last Name
                    </th>
                    <th className="px-2 py-1 text-gray-700 text-sm ">
                      Org Name
                    </th>
                    <th className="px-2 py-1 text-gray-700 text-sm ">
                      Org Type
                    </th>
                  </thead>
                  <tbody className="">
                    {isUserListLoading ? (
                      <div className="flex justify-center flex-col items-center">
                        <p>Loading...</p>
                      </div>
                    ) : (
                      userList?.users?.map((user) => {
                        return (
                          <tr
                            className="cursor-pointer"
                            onClick={() => {
                              let user_lists = Object.keys(selectedUserList);
                              let old_user_list = JSON.parse(
                                JSON.stringify(selectedUserList)
                              );
                              if (user_lists.includes(String(user.user_id))) {
                                delete old_user_list[user.user_id];
                              } else {
                                old_user_list[user.user_id] = user;
                              }
                              setSelectedUserList(old_user_list);
                            }}
                            key={user.user_id}
                          >
                            <td className="whitespace-nowrap  px-2 py-2 text-sm text-gray-900">
                              <input
                                type="checkbox"
                                readOnly
                                checked={Object.keys(selectedUserList).includes(
                                  String(user?.user_id)
                                )}
                              />
                            </td>
                            <td className="whitespace-nowrap  px-2 py-2 text-sm text-gray-900">
                              {user.username}
                            </td>
                            <td className="whitespace-nowrap  px-2 py-2 text-sm text-gray-900">
                              {user.first_name}
                            </td>
                            <td className="whitespace-nowrap  px-2 py-2 text-sm text-gray-900">
                              {user.last_name}
                            </td>
                            <td className="whitespace-nowrap  px-2 py-2 text-sm text-gray-900">
                              {org_id_to_details_mappnig[user.org_id]?.name ||
                                "not known"}
                            </td>
                            <td className="whitespace-nowrap  px-2 py-2 text-sm text-gray-900">
                              {org_id_to_details_mappnig[user.org_id]
                                ?.account_type?.name || "not known"}
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
              <div className="mt-2 flex justify-between items-center">
                <div className="flex space-x-1 items-center">
                  {userList.current_page > 1 && (
                    <button
                      disabled={isUserListLoading}
                      onClick={() => {
                        setUserPaginatedPageNumber((prev) => prev - 1);
                      }}
                      className="disabled:cursor-not-allowed text-black underline px-0 py-0 rounded-lg"
                    >
                      Back
                    </button>
                  )}

                  <div className="p-1 rounded border-black border-2 flex space-x-1 items-center">
                    <input
                      value={
                        isUserListLoading
                          ? "loading..."
                          : userPaginatedIntermidatePageNumber
                      }
                      type="number"
                      className="disabled:cursor-not-allowed mx-2 w-10"
                      disabled={isUserListLoading}
                      onChange={(e) => {
                        setUserPaginatedIntermidatePageNumber(
                          Number(e.target.value)
                        );
                      }}
                    />
                    <button
                      onClick={() => {
                        if (
                          userPaginatedIntermidatePageNumber > 0 &&
                          userPaginatedIntermidatePageNumber <=
                            userList.num_pages
                        ) {
                          setUserPaginatedPageNumber(
                            userPaginatedIntermidatePageNumber
                          );
                        } else {
                          alert("Please enter correct number range");
                        }
                      }}
                      className="bg-black px-1 py-1 text-xs rounded text-white"
                    >
                      Apply
                    </button>
                  </div>
                  {userList.current_page < userList.num_pages && (
                    <button
                      disabled={isUserListLoading}
                      onClick={() => {
                        setUserPaginatedPageNumber((prev) => prev + 1);
                      }}
                      className="ml-3 disabled:cursor-not-allowed text-black underline px-0 py-0 rounded-lg"
                    >
                      Next
                    </button>
                  )}
                </div>
                <div className="flex space-x-1">
                  <div>Total Pages: {userList.num_pages}</div>

                  <div>Total Users: {userList.total_users}</div>
                </div>
              </div>
            </div>

            {/* <div className="p-3 mt-2 bg-white w-full rounded-lg border">
              <div className="overflow-y-auto h-36 mt-2 bg-slate-100 border p-1 rounded-lg w-full">
                <table className="w-full ">
                  <thead className="border-b text-left">
                    <th className="px-2 py-1 text-gray-700 text-sm ">Select</th>
                    <th className="px-2 py-1 text-gray-700 text-sm ">Name</th>
                    <th className="px-2 py-1 text-gray-700 text-sm ">Label</th>
                  </thead>
                  <tbody className="divide-y">
                    {reportListLoading ? (
                      <div className="flex justify-center flex-col items-center">
                        <p>Loading...</p>
                      </div>
                    ) : (
                      allReportsTypes?.map((user) => {
                        return (
                          <tr
                            className="cursor-pointer"
                            onClick={() => {
                              if (selectedReportType.includes(user.name)) {
                                setSelectedReportType((prev) =>
                                  prev.filter((it) => it != user.name)
                                );
                              } else {
                                setSelectedReportType((prev) => [
                                  ...prev,
                                  user.name,
                                ]);
                              }
                            }}
                            key={user.name}
                          >
                            <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                              <input
                                type="checkbox"
                                readOnly
                                checked={selectedReportType.includes(user.name)}
                              />
                            </td>
                            <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                              {user.name}
                            </td>
                            <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                              {user.label}
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div> */}

            <p className="font-medium bg-white rounded text-sm mt-4">
              Selected Users :{" "}
            </p>
            <div className="p-2 mt-2  bg-white w-full rounded-lg border">
              <div className="h-9 overflow-y-auto bg-white flex flex-wrap space-x-2">
                {Object.keys(selectedUserList).map((user) => (
                  <div
                    onClick={() => {
                      let userSelected_obj = JSON.parse(
                        JSON.stringify(selectedUserList)
                      );

                      delete userSelected_obj[Number(user)];
                      setSelectedUserList(userSelected_obj);
                    }}
                    className="px-2 py-1 rounded bg-slate-100"
                    key={user}
                  >
                    {selectedUserList[user]?.username} X
                  </div>
                ))}
              </div>
            </div>

            <p className="font-medium bg-white rounded text-sm mt-4">
              Selected Orgs :{" "}
            </p>
            <div className="p-2 mt-2  bg-white w-full rounded-lg border">
              <div className="h-9 overflow-y-auto bg-white flex flex-wrap space-x-2">
                {Object.keys(selectedOrgList).map((org) => (
                  <div
                    onClick={() => {
                      let userSelected_obj = JSON.parse(
                        JSON.stringify(selectedOrgList)
                      );

                      delete userSelected_obj[Number(org)];
                      setSelectedOrgList(userSelected_obj);
                    }}
                    className="px-2 py-1 rounded bg-slate-100"
                    key={org}
                  >
                    {selectedOrgList[org]?.name} X
                  </div>
                ))}
              </div>
            </div>

            {/* <p className="font-medium bg-white rounded text-sm mt-4">
              Selected Report Type :{" "}
            </p>
            <div className="p-2 mt-2  bg-white w-full rounded-lg border">
              <div className="h-9 overflow-y-auto bg-white flex flex-wrap space-x-2">
                {selectedReportType.map((user) => (
                  <button
                    onClick={() => {
                      setSelectedReportType((prev) =>
                        prev.filter((it) => it != user)
                      );
                    }}
                    className="px-2 py-1 rounded bg-slate-100"
                    key={user}
                  >
                    {user} x
                  </button>
                ))}
              </div>
            </div> */}

            <p className="font-medium bg-white rounded text-sm mt-4 mb-2">
              Select from Date :{" "}
            </p>
            <Input
              className="w-full mt-2"
              value={filterTimeRange.startDate}
              type={"date"}
              onChange={(e) => {
                setFilterTimeRange((prev) => ({
                  ...prev,
                  startDate: e.target.value,
                }));
              }}
              placeholder="Enter the email"
            />
            <p className="font-medium bg-white rounded text-sm mt-4 mb-2">
              Select to Date :{" "}
            </p>
            <Input
              className="w-full mt-2"
              value={filterTimeRange.endDate}
              type={"date"}
              onChange={(e) => {
                setFilterTimeRange((prev) => ({
                  ...prev,
                  endDate: e.target.value,
                }));
              }}
              placeholder="Enter the email"
            />
          </div>
        </div>
      </ModalComp>
    </div>
  );
};

export default FilterModal;
