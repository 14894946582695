import React, { useEffect, useState } from "react";
import { parseAbsoluteToLocal } from "@internationalized/date";
import { DatePicker } from "@adobe/react-spectrum";
import { defaultTheme, Provider } from "@adobe/react-spectrum";
import Input from "../components/input";
import useAxiosInstance from "../hooks/useAxios";
import { useDebounce } from "use-debounce";
import JsonView from "@uiw/react-json-view";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const FormRender = ({ formData, setFormData, formSettings, select_options = null }) => {
  const [userList, setUserList] = useState({});
  const [
    userPaginatedIntermidatePageNumber,
    setUserPaginatedIntermidatePageNumber,
  ] = useState(1);
  const { organisation } = useSelector(e => e.globalAuth)
  console.log(organisation)
  const [userPaginatedPageNumber, setUserPaginatedPageNumber] = useState(1);
  const [userSearch, setUserSearch] = useState("");
  const [userQuery] = useDebounce(userSearch, 1000);

  const [isUserListLoading, setIsUserListLoading] = useState(false);
  useEffect(() => {
    setIsUserListLoading(true);
    // let new_orgs_ids = Object.keys(selectedOrgList).map((elem) => Number(elem));
    const controller = new AbortController();
    axiosInstance
      .get(
        `/admin/get-org-users/?&pgsz=20&page=${userPaginatedPageNumber}&query=${userQuery}`,
        {
          signal: controller.signal,
        }
      )
      .then((res) => {
        setUserList(res.data);
        setIsUserListLoading(false);
        setUserPaginatedIntermidatePageNumber(res.data.current_page);
      })
      .catch((err) => {
        console.log(err);
        // toast.error("Something went while getting users");
        // setIsUserListLoading(false);
      });

    return () => {
      controller.abort();
    };
  }, [userPaginatedPageNumber, userQuery]);


  const axiosInstance = useAxiosInstance();
  //   console.log(select_options?.['type'])
  //   select_options?.[setting.saveKey]
  const [dateTimeLists, setDateTimeLists] = useState([]);
  useEffect(() => {
    const pe = []
    formSettings.map((setting) => {
      if (formData?.[setting.saveKey] === null && setting.type === "date__time") {
        pe.push(setting.saveKey)
      }
    })
    setDateTimeLists(pe)
  }, [formData])


  useEffect(() => {
    setOrgLoading(true);
    axiosInstance
      .get("admin/organisations/")
      .then((res) => {
        setOrgs(res.data);
        let new_mapping = {};
        let all_orgs = res?.data?.organisations;
        all_orgs.forEach((org) => {
          new_mapping[org.org_id] = org;
        });
        setOrgLoading(false);
        // setcompanyRequestPaginatedPageNumber(1)
        setOrg_id_to_details_mapping(new_mapping);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went while getting users");
        setOrgLoading(false);
      });
  }, []);

  const [orgLoadings, setOrgLoading] = useState(false);
  const [org_id_to_details_mappnig, setOrg_id_to_details_mapping] = useState(
    {}
  );
  const [orgs, setOrgs] = useState({});
  const [searchOrg, setSeachOrg] = useState("");

  const getOrgSearchData = (searchOrg, orgs) => {
    if (searchOrg === "") {
      return orgs;
    } else {
      return orgs?.filter((item) =>
        item?.name?.toLowerCase()?.includes(searchOrg?.toLowerCase())
      );
    }
  };

  const getOrgImage = (img, name) => {
    if (img === null) {
      return `https://wklogo.blob.core.windows.net/logos-small/${name[0]?.toUpperCase()}.png`;
    } else {
      return img;
    }
  };
  return (
    <div>
      {(formSettings||[])?.map((setting) => {
        if (setting.type === "org") {
          return <>          <div className="text-sm font-semibold mt-4 mb-2">
            {setting.label} :- {(Object.keys(formData?.[setting.saveKey] || {}).map(it => (formData?.[setting.saveKey] || {})?.[it].name)).join(', ')}
          </div>{" "}
            <div key={setting.saveKey} className=" bg-white w-full rounded-lg ">
              <Input
                value={searchOrg}
                onChange={(e) => {
                  setSeachOrg(e.target.value);
                }}
                type="text"
                placeholder="Search Orgs"
              />
              <div className="overflow-y-auto h-36 mt-2 bg-slate-100 border p-1 rounded-lg w-full">
                <table className="w-full ">
                  <thead className="border-b text-left">
                    <th className="px-2 py-1 text-gray-700 text-sm ">Select</th>
                    <th className="px-2 py-1 text-gray-700 text-sm ">
                      Org Logo
                    </th>
                    <th className="px-2 py-1 text-gray-700 text-sm ">Name</th>
                    <th className="px-2 py-1 text-gray-700 text-sm ">Label</th>
                    <th className="px-2 py-1 text-gray-700 text-sm ">
                      Account Type
                    </th>
                  </thead>
                  <tbody className="divide-y">
                    {orgLoadings ? (
                      <div className="flex justify-center flex-col items-center">
                        <p>Loading...</p>
                      </div>
                    ) : (
                      getOrgSearchData(
                        searchOrg,
                        orgs?.organisations || []
                      ).map((org) => {
                        return (
                          <tr
                            className="cursor-pointer"
                            onClick={() => {
                              let org_lists =
                                Object.keys(formData?.[setting.saveKey] || {});
                              let old_org_list = JSON.parse(
                                JSON.stringify(formData?.[setting.saveKey] || {})
                              );
                              if (org_lists.includes(String(org.org_id))) {
                                delete old_org_list[org.org_id];
                              } else {
                                old_org_list[org.org_id] = org;
                              }
                              let newformData = { ...formData }
                              newformData[setting.saveKey] = old_org_list
                              setFormData(newformData)
                            }}
                            key={org.org_id}
                          >
                            <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                              <input
                                type="checkbox"
                                readOnly
                                checked={Object.keys(formData?.[setting.saveKey]).includes(
                                  String(org?.org_id)
                                )}
                              />
                            </td>
                            <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                              {
                                <div
                                  style={{
                                    backgroundImage: `url('${getOrgImage(
                                      org?.org_img,
                                      org?.name
                                    )}')`,
                                  }}
                                  className="h-8 w-8 bg-center bg-no-repeat bg-contain"
                                />
                              }
                            </td>
                            <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                              {org?.name}
                            </td>
                            <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                              {org?.label}
                            </td>
                            <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                              {org?.account_type?.name}
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        }
        if (setting.type === "user") {
          return (
            <div key={setting.saveKey} className=" mt-4 ">
              <div className="text-sm font-semibold  mb-2">
                {setting.label} :- {(Object.keys(formData?.[setting.saveKey] || {}).map(it => (formData?.[setting.saveKey] || {})?.[it].email)).join(', ')}
              </div>{" "}
              <div className="p-3 bg-white w-full rounded-lg ">
                <Input
                  value={userSearch}
                  onChange={(e) => {
                    setUserSearch(e.target.value);
                  }}
                  type="text"
                  placeholder="Search User"
                />
                <div className="overflow-y-auto h-36 mt-2 bg-slate-100 border p-1 rounded-lg w-full">
                  <table className="w-full ">
                    <thead className="border-b text-left">
                      <th className="px-2 py-1 text-gray-700 text-sm ">
                        Select
                      </th>
                      <th className="px-2 py-1 text-gray-700 text-sm ">
                        UserName
                      </th>
                      <th className="px-2 py-1 text-gray-700 text-sm ">
                        First Name
                      </th>
                      <th className="px-2 py-1 text-gray-700 text-sm ">
                        Last Name
                      </th>
                      <th className="px-2 py-1 text-gray-700 text-sm ">
                        Org Name
                      </th>
                      <th className="px-2 py-1 text-gray-700 text-sm ">
                        Org Type
                      </th>
                    </thead>
                    <tbody className="">
                      {isUserListLoading ? (
                        <div className="flex justify-center flex-col items-center">
                          <p>Loading...</p>
                        </div>
                      ) : (
                        userList?.users?.map((user) => {
                          return (
                            <tr
                              className="cursor-pointer"
                              onClick={() => {
                                let user_lists =
                                  Object.keys(formData?.[setting.saveKey] || {});
                                let old_user_list = JSON.parse(
                                  JSON.stringify(formData?.[setting.saveKey] || {})
                                );
                                if (
                                  user_lists.includes(String(user.user_id))
                                ) {
                                  delete old_user_list[user.user_id];
                                } else {
                                  old_user_list[user.user_id] = user;
                                }
                                let newformData = { ...formData }
                                newformData[setting.saveKey] = old_user_list
                                setFormData(newformData)
                              }}
                              key={user.user_id}
                            >
                              <td className="whitespace-nowrap  px-2 py-2 text-sm text-gray-900">
                                <input
                                  type="checkbox"
                                  readOnly
                                  checked={Object.keys(
                                    formData?.[setting.saveKey]
                                  ).includes(String(user?.user_id))}
                                />
                              </td>
                              <td className="whitespace-nowrap  px-2 py-2 text-sm text-gray-900">
                                {user.username}
                              </td>
                              <td className="whitespace-nowrap  px-2 py-2 text-sm text-gray-900">
                                {user.first_name}
                              </td>
                              <td className="whitespace-nowrap  px-2 py-2 text-sm text-gray-900">
                                {user.last_name}
                              </td>
                              <td className="whitespace-nowrap  px-2 py-2 text-sm text-gray-900">
                                {org_id_to_details_mappnig?.[user.org_id]?.name ||
                                  "not known"}
                              </td>
                              <td className="whitespace-nowrap  px-2 py-2 text-sm text-gray-900">
                                {org_id_to_details_mappnig?.[user.org_id]
                                  ?.account_type?.name || "not known"}
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="mt-2 flex justify-between items-center">
                  <div className="flex space-x-1 items-center">
                    {userList.current_page > 1 && (
                      <button
                        disabled={isUserListLoading}
                        onClick={() => {
                          setUserPaginatedPageNumber((prev) => prev - 1);
                        }}
                        className="disabled:cursor-not-allowed text-black underline px-0 py-0 rounded-lg"
                      >
                        Back
                      </button>
                    )}

                    <div className="p-1 rounded border-black border-2 flex space-x-1 items-center">
                      <input
                        value={
                          isUserListLoading
                            ? "loading..."
                            : userPaginatedIntermidatePageNumber
                        }
                        type="number"
                        className="disabled:cursor-not-allowed mx-2 w-10"
                        disabled={isUserListLoading}
                        onChange={(e) => {
                          setUserPaginatedIntermidatePageNumber(
                            Number(e.target.value)
                          );
                        }}
                      />
                      <button
                        onClick={() => {
                          if (
                            userPaginatedIntermidatePageNumber > 0 &&
                            userPaginatedIntermidatePageNumber <=
                            userList.num_pages
                          ) {
                            setUserPaginatedPageNumber(
                              userPaginatedIntermidatePageNumber
                            );
                          } else {
                            alert("Please enter correct number range");
                          }
                        }}
                        className="bg-black px-1 py-1 text-xs rounded text-white"
                      >
                        Apply
                      </button>
                    </div>
                    {userList.current_page < userList.num_pages && (
                      <button
                        disabled={isUserListLoading}
                        onClick={() => {
                          setUserPaginatedPageNumber((prev) => prev + 1);
                        }}
                        className="ml-3 disabled:cursor-not-allowed text-black underline px-0 py-0 rounded-lg"
                      >
                        Next
                      </button>
                    )}
                  </div>
                  <div className="flex space-x-1">
                    <div>Total Pages: {userList.num_pages}</div>

                    <div>Total Users: {userList.total_users}</div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
        if (setting.type === "select") {
          return (
            <div key={setting.saveKey} className=" mt-4 ">
              <div className="text-sm font-semibold mb-2">
                {setting.label}
              </div>{" "}
              <select
                key={setting.saveKey}
                value={formData?.[setting.saveKey]}
                type={setting.type}
                onChange={(e) => {
                  let newFormData = { ...formData };
                  newFormData[setting.saveKey] = e.target.value;
                  setFormData(newFormData);
                }}
                className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 "
                placeholder="Enter your password"
              >
                <option value={""}>Choose</option>
                {(select_options?.[setting.saveKey] || setting?.options)
                  .map((it) => (
                    <option key={it.label} value={it.value}>
                      {it.label}
                    </option>
                  ))}
              </select>
            </div>
          );
        }
        if (setting.type === "date__time") {

          return (
            <div key={setting.saveKey} className=" mt-4 ">
              <div className="text-sm font-semibold mb-2">
                {setting.label} {!dateTimeLists.includes(setting.saveKey) && <button onClick={() => {
                  setDateTimeLists(prev => [...prev, setting.saveKey])
                  let newFormData = { ...formData };
                  newFormData[setting.saveKey] = null;
                  setFormData(newFormData);
                }} className="bg-black text-white px-2 py-1 rounded-lg text-xs font-semibold">mark as null</button>}
              </div>
              {dateTimeLists.includes(setting.saveKey) ? <p>Marked as null (<button onClick={() => {
                setDateTimeLists((prev) => prev.filter(it => it !== setting.saveKey))
                let newFormData = { ...formData };
                newFormData[setting.saveKey] = "2999-12-31T00:00:00Z";
                setFormData(newFormData);
              }} className="bg-black text-white px-2 py-1 rounded-lg text-xs font-semibold">choose date</button>)</p> : <Provider theme={defaultTheme}>
                <DatePicker
                  onChange={(value) => {
                    let newFormData = { ...formData };
                    newFormData[setting.saveKey] =
                      value.toAbsoluteString();
                    setFormData(newFormData);
                  }}
                  defaultValue={parseAbsoluteToLocal(
                    formData?.[setting.saveKey] || "2999-12-31T00:00:00Z"
                  )}
                />
              </Provider>}
            </div>
          );
        }
        if (setting.type === "json") {
          return (
            <div key={setting.saveKey} className=" mt-4 ">
              <div className="text-sm font-semibold mb-2">
                {setting.label}
              </div>
              <JsonView
                shortenTextAfterLength={0}
                value={formData?.[setting.saveKey] || {}}
                displayObjectSize={false}
                displayDataTypes={false}
              />
            </div>)
        }
        return (
          <div key={setting.saveKey} className=" mt-4 ">
            <div className="text-sm font-semibold mb-2">
              {setting.label}
            </div>
            <Input
              value={setting?.realValues ? setting?.realValues.find(i => i?.['pk'] === formData?.[setting.saveKey])?.label : formData?.[setting.saveKey]}
              type={setting.type}
              disabled={setting.type === "readonly"}
              onChange={(e) => {
                let newFormData = { ...formData };
                newFormData[setting.saveKey] = e.target.value;
                setFormData(newFormData);
              }}
              placeholder={"Enter " + setting.label}
            />
          </div>
        );
      })}
    </div>
  )
}

export default FormRender